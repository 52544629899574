<template>
  <l-map
    :zoom="18"
    :center="[position.lat, position.lng]"
  >
    <l-tile-layer
      :url="tileProvider.url"
      :attribution="tileProvider.attribution"
    />
    <l-marker
      v-if="position.lat && position.lng"
      visible
      draggable
      :icon="icon"
      :lat-lng.sync="position"
    >
      <!-- <l-tooltip :content="tooltipContent" :options="{ permanent: true }" /> -->
    </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { icon } from 'leaflet'
export default {
  props: {
    datas: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      position: { lat: null, lng: null },
      icon: icon({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      tileProvider: {
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    // LTooltip,
  },
  created() {
    this.position = {
      ...this.position,
      lat: this.datas.geolokasi_tempat_tinggal?.latitude,
      lng: this.datas.geolokasi_tempat_tinggal?.longitude,
    }
  },
}
</script>

<style>

</style>
